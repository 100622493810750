/* General Styles */
body {
  font-family: 'Inter', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f5f7fa;
  color: #333;
  overflow-x: hidden; /* Prevent horizontal overflow */
}

.header {
  background-color: #1e3a8a;
  color: #ffffff;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  box-sizing: border-box;
}

.header-logo {
  height: 50px;
  filter: invert(9%) sepia(10%) saturate(321%) hue-rotate(176deg) brightness(100%) contrast(101%);
}

.main-logo {
  font-size: 1.5rem;
  font-weight: 600;
  height: 15rem;
  padding: 20px;
  padding-bottom: 50px;
  filter: invert(91%) sepia(10%) saturate(321%) hue-rotate(176deg) brightness(100%) contrast(101%);
}

.nav {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.nav a {
  color: #ffffff;
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s;
}

.nav a:hover {
  color: #ffd700;
}

.hero {
  background: linear-gradient(135deg, #1e3a8a 0%, #6366f1 100%);
  color: #ffffff;
  text-align: center;
  padding: 120px 20px;
  position: relative;
  box-sizing: border-box;
}

.hero h1 {
  font-size: 3.5rem;
  margin-bottom: 20px;
}

.hero p {
  font-size: 1.4rem;
  max-width: 800px;
  margin: 0 auto 40px;
  line-height: 1.6;
}

.cta-button {
  padding: 15px 30px;
  text-decoration: none;
  font-weight: 600;
  border-radius: 50px;
  transition: background-color 0.3s, color 0.3s;
  background-color: #ffd700;
}

.cta-button:hover {
  background-color: #fbbf24;
  color: #ffffff;
}

.content-section {
  padding: 60px 20px;
  text-align: center;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  margin: 40px auto;
  max-width: 1200px;
  border-radius: 10px;
  box-sizing: border-box;
}

.content-section p {
  max-width: 800px;
  margin: 0 auto 30px;
  font-size: 1.3rem;
  line-height: 1.6;
}

.content-section h2 {
  margin-bottom: 20px;
  font-size: 2.5rem;
  margin: 0 auto 30px;
}

.services {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 30px;
}

.service {
  background-color: #f1f5f9;
  padding: 30px;
  border: 1px solid #e5e7eb;
  width: 100%;
  max-width: 280px;
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  box-sizing: border-box;
}

.service:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.customers {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 30px;
}

.customer {
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  width: 100%;
  max-width: 400px;
  box-sizing: border-box;
}

.customer-img {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.customer:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.footer {
  background-color: #1e3a8a;
  color: #ffffff;
  text-align: center;
  padding: 40px 20px;
  margin-top: 60px;
  box-sizing: border-box;
}

.footer a {
  color: #ffd700;
  text-decoration: none;
  font-weight: 600;
}

.footer a:hover {
  color: #fbbf24;
}

/* Media Queries for Mobile Devices */
@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }

  .header-logo {
    height: 30px; /* Reduce logo size for smaller screens */
  }
  
  .main-logo {
    height: 8rem; /* Adjust the height to reduce the size further for mobile */
    padding-bottom: 20px;
  }
  
  .nav {
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 10px;
  }
  
  .nav a {
    margin: 5px 10px;
    font-size: 1rem; /* Adjust font size for better readability on smaller screens */
  }
  
  .hero {
    padding: 80px 20px;
  }
  
  .hero h1 {
    font-size: 2.5rem;
  }
  
  .hero p {
    font-size: 1.2rem;
    line-height: 1.4; /* Adjust line height for better readability */
  }
  
  .content-section {
    padding: 40px 20px;
  }
  
  .content-section h2 {
    font-size: 2rem;
  }
  
  .content-section p {
    font-size: 1.1rem;
  }
  
  .services, .customers {
    flex-direction: column;
    align-items: center;
  }
  
  .service, .customer {
    width: 100%;
    max-width: 400px;
    margin-bottom: 20px; /* Add margin to create spacing between items */
  }

  .customer-img {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 480px) {
  .hero {
    padding: 60px 20px;
  }
  
  .hero h1 {
    font-size: 2rem;
  }
  
  .hero p {
    font-size: 1rem;
  }
  
  .cta-button {
    padding: 10px 20px;
    font-size: 1rem;
  }
  
  .content-section {
    padding: 30px 20px;
  }
  
  .content-section h2 {
    font-size: 1.8rem;
  }
  
  .content-section p {
    font-size: 1rem;
  }

  .nav a {
    font-size: 0.9rem; /* Further reduction for very small screens */
  }
}
